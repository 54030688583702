export const ROUTES = {
  KEYWORDS_IG: "/keywords/ig",
  ERP_ATTENDANCE: "/erp/attendance",
  ERP_LEAVES: "/erp/leaves",
  ADMIN_CDR: "/admin/cdr",
  ADMIN_USER_MANAGEMENT: "/admin/users",
  ADMIN_USER_MEMBERSHIPS: "/admin/users/memberships",
  ADMIN_LEAVE_MANAGEMENT: "/admin/leaves",
  ADMIN_TIMESHEETS: "/admin/timesheets",
  ADMIN_HOLIDAYS: "/admin/holidays",
  BVS_ORDERS: "/bvs/orders",
  BVS_PRODUCT_FEED: "/bvs/product/feed",
  TRAVEL_LEADS: "/admin/travelLeads",
  BVS_TALLY_EXPORT: "/bvs/tally"
}
