import React, { Component } from "react"
import "./App.scss"
import "antd/dist/antd.css"
import { HashRouter as Router } from "react-router-dom"
import { SideMenu, IMenuItems } from "heur-design"
import logo from "./assets/images/logo.png"
import { RouteContainer } from "./containers/RouteContainer/RouteContainer"
import { ErrorBoundary } from "heur-design"
import { ROUTES } from "./containers/RouteContainer/Routes"
import { Layout } from "antd"

const App = () => {
  const menuItems: IMenuItems[] = [
    {
      title: "ERP",
      icon: "user",
      children: [
        {
          title: "Attendance",
          link: ROUTES.ERP_ATTENDANCE,
          icon: "check-circle"
        },
        {
          title: "Leaves",
          link: ROUTES.ERP_LEAVES,
          icon: "pause-circle"
        }
      ]
    },
    {
      title: "Instagram Keywords",
      link: ROUTES.KEYWORDS_IG,
      icon: "search"
    },
    {
      title: "Admin",
      icon: "setting",
      children: [
        {
          title: "CDRs",
          link: ROUTES.ADMIN_CDR,
          icon: "phone",
          permission: "ALL:CDR:VIEW"
        },
        {
          title: "BVS Orders",
          link: ROUTES.BVS_ORDERS,
          icon: "money-collect",
          permission: "ALL:BVS:VIEW"
        },
        {
          title: "BVS Product Feed",
          link: ROUTES.BVS_PRODUCT_FEED,
          icon: "file",
          permission: "ALL:BVS:VIEW"
        },
        {
          title: "BVS Tally Export",
          link: ROUTES.BVS_TALLY_EXPORT,
          icon: "file",
          permission: "ALL:BVS:VIEW"
        },
        {
          title: "Travel Leads",
          link: ROUTES.TRAVEL_LEADS,
          icon: "file",
          permission: "ALL:CDR:VIEW"
        },
        {
          title: "User Management",
          link: ROUTES.ADMIN_USER_MANAGEMENT,
          icon: "user-add",
          permission: "ALL:USER:VIEW"
        },
        {
          title: "User Memberships",
          link: ROUTES.ADMIN_USER_MEMBERSHIPS,
          icon: "usergroup-add",
          permission: "ALL:USER_MEMBERSHIP:VIEW"
        },
        {
          title: "Leave Management",
          link: ROUTES.ADMIN_LEAVE_MANAGEMENT,
          icon: "pause-circle",
          permission: "ALL:LEAVES:ADMIN"
        },
        {
          title: "Timesheet Management",
          link: ROUTES.ADMIN_TIMESHEETS,
          icon: "clock-circle",
          permission: "ALL:LEAVES:ADMIN"
        },
        {
          title: "Holiday Management",
          link: ROUTES.ADMIN_HOLIDAYS,
          icon: "clock-circle",
          permission: "ALL:LEAVES:ADMIN"
        }
      ]
    }
  ]

  return (
    <ErrorBoundary>
      <div className="app-container">
        <Router>
          <Layout style={{ height: "100%" }}>
            <SideMenu logo={logo} menuItems={menuItems} />
            <Layout>
              <RouteContainer />
            </Layout>
          </Layout>
        </Router>
      </div>
    </ErrorBoundary>
  )
}

export default App
