import ApolloClient from "apollo-boost"
import { InMemoryCache } from "apollo-cache-inmemory"
import { message } from "antd"
import { API_BASE_URL } from "../settings"
import { ServerError, ServerParseError } from "apollo-link-http-common"

export const client = new ApolloClient({
  uri: `${API_BASE_URL}/graphql`,
  cache: new InMemoryCache(),
  onError: ({ graphQLErrors, networkError }) => {
    const netError = networkError as ServerError
    if (netError && netError.statusCode && netError.statusCode === 401) {
      window.location.href = `${API_BASE_URL}/login`
    }
    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        message.error(error.message)
      })
    }
  }
})
