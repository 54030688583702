import * as React from "react"
import {
  Route,
  Switch,
  Redirect,
  RouteComponentProps,
  withRouter
} from "react-router-dom"
import { ROUTES } from "./Routes"
import { ErrorBoundary, Loader } from "heur-design"
import { PageHeader } from "heur-design"
import "./RouteContainer.scss"

const InstagramContainer = React.lazy(() =>
  import("../Keywords/Instagram/Instagram")
)
const Attendance = React.lazy(() => import("../ERP/Attendance/Attendance"))
const Leaves = React.lazy(() => import("../ERP/Leaves/Leaves"))
const CDR = React.lazy(() => import("../Admin/CDR/CDR"))
const BVSOrders = React.lazy(() => import("../BVS/Orders/Orders"))
const BVSProductFeed = React.lazy(() =>
  import("../BVS/ProductFeed/ProductFeed")
)
const UserManagement = React.lazy(() =>
  import("../Admin/UserManagement/UserManagement")
)
const UserMembership = React.lazy(() =>
  import("../Admin/UserMembership/UserMembership")
)
const AdminLeaveManagement = React.lazy(() =>
  import("../Admin/LeaveManagement/LeaveManagement")
)
const TimesheetManagement = React.lazy(() =>
  import("../Admin/TimeSheetManagement/TimeSheetManagement")
)

const HolidayManagement = React.lazy(() =>
  import("../Admin/HolidayManagement/HolidayManagement")
)

const TravelLeads = React.lazy(() => import("../Admin/TravelLeads"))

const BVSTallyExport = React.lazy(() =>
  import("../BVS/TallyExport/TallyExport")
)

const DefaultRoute = () => <Redirect to={ROUTES.KEYWORDS_IG} />

const RouteContainerComponent: React.FC<RouteComponentProps> = props => {
  const routeTitleMap = {
    [ROUTES.KEYWORDS_IG]: "Instagram",
    [ROUTES.ERP_ATTENDANCE]: "Attendance",
    [ROUTES.ERP_LEAVES]: "Leaves",
    [ROUTES.ADMIN_CDR]: "CDR",
    [ROUTES.BVS_ORDERS]: "Big Value Shop Orders",
    [ROUTES.ADMIN_USER_MANAGEMENT]: "User Management",
    [ROUTES.ADMIN_USER_MEMBERSHIPS]: "User Memberships",
    [ROUTES.ADMIN_LEAVE_MANAGEMENT]: "Leave Management",
    [ROUTES.ADMIN_TIMESHEETS]: "TimeSheet Management",
    [ROUTES.ADMIN_HOLIDAYS]: "Holiday Management",
    [ROUTES.BVS_PRODUCT_FEED]: "Product Feed",
    [ROUTES.TRAVEL_LEADS]: "Travel Leads",
    [ROUTES.BVS_TALLY_EXPORT]: "BVS Tally Export"
  }
  return (
    <div className="route-container">
      <ErrorBoundary location={props.location.pathname}>
        <PageHeader routeTitleMap={routeTitleMap} />
        <React.Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Switch>
            <Route path={ROUTES.KEYWORDS_IG} component={InstagramContainer} />
            <Route path={ROUTES.ERP_ATTENDANCE} component={Attendance} />
            <Route path={ROUTES.ERP_LEAVES} component={Leaves} />
            <Route path={ROUTES.ADMIN_CDR} component={CDR} />
            <Route path={ROUTES.BVS_ORDERS} component={BVSOrders} />
            <Route path={ROUTES.TRAVEL_LEADS} component={TravelLeads} />
            <Route path={ROUTES.BVS_TALLY_EXPORT} component={BVSTallyExport} />
            <Route
              path={ROUTES.ADMIN_USER_MANAGEMENT}
              component={UserManagement}
              exact
            />
            <Route
              path={ROUTES.ADMIN_USER_MEMBERSHIPS}
              component={UserMembership}
              exact
            />
            <Route
              path={ROUTES.ADMIN_LEAVE_MANAGEMENT}
              component={AdminLeaveManagement}
              exact
            />
            <Route
              path={ROUTES.ADMIN_TIMESHEETS}
              component={TimesheetManagement}
              exact
            />
            <Route
              path={ROUTES.ADMIN_HOLIDAYS}
              component={HolidayManagement}
              exact
            />
            <Route
              path={ROUTES.BVS_PRODUCT_FEED}
              component={BVSProductFeed}
              exact
            />
            <Route component={DefaultRoute} />
          </Switch>
        </React.Suspense>
      </ErrorBoundary>
    </div>
  )
}

export const RouteContainer = withRouter(RouteContainerComponent)
