import React from "react"
import ReactDOM from "react-dom"
import "./styles/reset.scss"
import "./styles/global.scss"
import App from "./App"
import "./index.scss"

import { ApolloProvider } from "react-apollo"
import { client } from "./graphql/client"
import { UserContextProvider } from "heur-design/dist/graphql"

ReactDOM.render(
  <ApolloProvider client={client}>
    <UserContextProvider>
      <App />
    </UserContextProvider>
  </ApolloProvider>,
  document.getElementById("root")
)
